import cx from 'clsx';
import { once } from 'ramda';
import * as React from 'react';

import { DynamicCalltrackingButton } from 'shared/components/DynamicCalltrackingButton';
import { FavoriteButtonContainer } from 'shared/containers/FavoriteButtonContainer';
import { FavoritesButtonContext } from 'shared/context';
import { IFavoriteButtonContext } from 'shared/context/favoritesButton';
import {
  IAgentSchema,
  IOfferSchema,
  IPhoneSchema,
  IUserSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { getCommercialAreaForMultiAds, getOfferTitle, getSquareInformation } from 'shared/utils/offer_helpers';
import { formatNumber } from 'shared/utils/phone';

import * as styles from './index.css';
import { trackShowPhones } from './tracking';

export interface IFullScreenControlsCommonProps {
  abGroup: number;
  agent: IAgentSchema | null;
  dynamicCalltrackingPlaceType: string;
  isAuthenticated: boolean;
  kpId: number | null;
  newbuildingId: number | null;
  offer: IOfferSchema;
  price: string | null;
  siteBlockId: number | null;
  user: IUserSchema | null;
  isOfferMultiAds: boolean;
  callButtonText: string;
}

export interface IFullScreenControlsOwnProps {
  hidePhoneButton?: boolean;
  hideFavoritesButton?: boolean;
  nextSliderCompat?: boolean;
  showDailyrentBookOnlineButton?: boolean;
  dailyrentCheckoutPageUrl?: string;
  exitFullscreen?(): void;
}

export interface IFullScreenControlsDispatchProps {
  setPhoneCollapse(): void;
}

export interface IFullscreenControlsState {
  showPhones: boolean;
}

export type IFullScreenControlsProps = IFullScreenControlsCommonProps &
  IFullScreenControlsDispatchProps &
  IFullScreenControlsOwnProps;

export class FullScreenControls extends React.Component<IFullScreenControlsProps, IFullscreenControlsState> {
  public constructor(props: IFullScreenControlsProps) {
    super(props);

    this.state = {
      showPhones: false,
    };
  }

  public render(): React.ReactNode {
    const { showPhones } = this.state;
    const {
      offer,
      siteBlockId,
      dynamicCalltrackingPlaceType,
      hidePhoneButton,
      hideFavoritesButton,
      callButtonText,
      showDailyrentBookOnlineButton,
      dailyrentCheckoutPageUrl,
    } = this.props;
    const { phones } = offer;

    const phonesAvailable = !hidePhoneButton && !!phones && !phones.some(phone => !phone.countryCode || !phone.number);
    const formattedPhones = phonesAvailable ? (phones as IPhoneSchema[]).map(formatNumber).join(', ') : '';

    return (
      <div
        data-name="FullScreenControls"
        className={cx(styles['controls'], { [styles['controls--next-compat']]: this.props.nextSliderCompat })}
      >
        <div className={styles['title']}>{this.getTitle()}</div>

        {showDailyrentBookOnlineButton && (
          <a className={styles['show-phone']} href={dailyrentCheckoutPageUrl} target="_blank" rel="noreferrer noopener">
            Забронировать
          </a>
        )}

        {phonesAvailable && (
          <DynamicCalltrackingButton
            announcementId={offer.id}
            blockId={siteBlockId}
            isOpen={showPhones}
            onClick={this.showPhones}
            openPhoneClassName={styles['phone']}
            phone={formattedPhones}
            placeType={dynamicCalltrackingPlaceType}
            buttonText={callButtonText}
            buttonSize="XS"
            buttonFullWidth={false}
          />
        )}

        {!hideFavoritesButton && (
          <div className={styles['favorites-wrapper']}>
            <FavoritesButtonContext.Provider value={this.getCustomFavoriteContext()}>
              <FavoriteButtonContainer />
            </FavoritesButtonContext.Provider>
          </div>
        )}

        {this.props.exitFullscreen && <div className={styles['close']} onClick={this.props.exitFullscreen} />}
      </div>
    );
  }

  private getCustomFavoriteContext = once(
    (): IFavoriteButtonContext => ({
      customClass: styles['favorites'],
      placement: 'gallery',
      popupPivotX: 'right' as const,
    }),
  );

  private getTitle(): string {
    const { offer, price, isOfferMultiAds } = this.props;

    const squareInformation = isOfferMultiAds
      ? getCommercialAreaForMultiAds(offer.minArea, offer.maxArea)
      : getSquareInformation(offer);

    return [getOfferTitle(offer), squareInformation, price].filter(Boolean).join(', ').trim();
  }

  private readonly showPhones = (): void => {
    const { abGroup, agent, kpId, offer, setPhoneCollapse, siteBlockId, user } = this.props;

    trackShowPhones(offer, agent, user, abGroup, kpId, siteBlockId);

    setPhoneCollapse();
    this.setState({ showPhones: true });
  };
}
